import React, { useState } from 'react';
import { Check, ChevronDown, ChevronUp, Rss } from 'lucide-react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Card } from '../../atoms/card';
import useOnClickOutside from '../../../hooks/useClickOutside';
import { getAPCAColor } from '../../../helpers/color';

export const RemoteLocationFilter = ({ updateFilters, locationOptionTag, hasAdvanceDesign }) => {
  const tagSet = new Set(locationOptionTag.map((item) => item.id));
  const options = [
    {
      label: 'On-site',
      value: 'on-site',
      subLabel: 'On-Site and hybrid',
      onClick: (item, keys) => {
        updateFilters({
          filter: { queryParam: 'searchable_location_option', value: 'on-site', name: 'on-site' },
          mode: keys.has(item.value) ? 'remove' : 'add',
        });
      },
    },
    {
      label: 'Remote',
      value: 'remote',
      subLabel: 'May include hybrid',
      onClick: (item, keys) => {
        updateFilters({
          filter: { queryParam: 'searchable_location_option', value: 'remote', name: 'remote' },
          mode: keys.has(item.value) ? 'remove' : 'add',
        });
      },
    },
  ];

  const selectedOptions = options.filter((item) => tagSet.has(item.value));
  const [showDropdown, setShowDropdown] = useState(false);
  const keys = new Set(selectedOptions.map((item) => item.value));

  const ref = useOnClickOutside(() => setShowDropdown(false));

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        maxWidth: ['100%', '100%', '216px'],
        position: 'relative',
      }}
    >
      <Flex
        data-testid="remote-filter-dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        sx={{
          border: '1px solid',
          borderColor: 'border.default',
          borderRadius: '4px',
          height: '56px',
          width: '100%',
          color: 'text.main',
          fontSize: '16px',
          lineHeight: '24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '12px 16px',
          cursor: 'pointer',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Box as={Rss} size="16px" />
          <Box as="p">{(selectedOptions.length ? selectedOptions : options).map((item) => item.label).join(' & ')}</Box>
        </Flex>
        <Box as={showDropdown ? ChevronUp : ChevronDown} size="16px" sx={{ color: 'text.subtle' }} />
      </Flex>
      {showDropdown && (
        <Card
          sx={{
            p: '8px',
            position: 'absolute',
            zIndex: 999999,
            width: '100%',
          }}
        >
          {options.map((item) => (
            <Flex
              key={item.value}
              onClick={() => {
                item?.onClick?.(item, keys);
              }}
              sx={{ cursor: 'pointer' }}
              p="8px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box fontSize="14px">
                <Box color="text.dark">{item.label}</Box>
                <Box color="text.subtle">{item.subLabel}</Box>
              </Box>
              {keys.has(item.value) && (
                <Box as={Check} size="16px" sx={{ color: getAPCAColor(hasAdvanceDesign).primary }} />
              )}
            </Flex>
          ))}
        </Card>
      )}
    </Box>
  );
};

RemoteLocationFilter.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  locationOptionTag: PropTypes.array.isRequired,
  hasAdvanceDesign: PropTypes.bool.isRequired,
};

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allJobsRequest } from '../redux/actions/jobs';
import { jobsSelector } from '../redux/selectors/jobs';
import useEncryptedRouter from './useEncryptedRouter';

const useJobs = ({ defaultQuery = {}, network }) => {
  const [page, setPage] = useState(1);
  const jobsData = useSelector(jobsSelector);
  const { found: jobs, total, filters, initialized, loading, isLazyLoading } = jobsData;
  const router = useEncryptedRouter();
  const currentQuery = router.query;
  const dispatch = useDispatch();

  const query = useMemo(
    () => ({
      ...currentQuery,
      ...defaultQuery,
    }),
    [currentQuery, defaultQuery],
  );

  useEffect(() => {
    if (!initialized && !loading) {
      dispatch(
        allJobsRequest({
          network,
          query: { ...query, page: 1 },
        }),
      );
    }
  }, [dispatch, initialized, loading, network, query, router, router.query.page]);

  const nextPage = () => {
    dispatch(
      allJobsRequest({
        network,
        query: { ...query, page: page + 1 },
      }),
    );

    setPage(page + 1);
  };

  return { jobs, page, total, filters, initialized, isLoading: loading, isLazyLoading, nextPage };
};

export default useJobs;

import React, { useMemo, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Card, Box } from 'rebass/styled-components';
import dynamic from 'next/dynamic';
import SearchResultsEmpty from '../../atoms/SearchResultsEmpty';
import { JobListItem } from '../../molecules/jobListItem';
import { ResultsList } from '../../molecules/resultsList';
import useJobs from '../../../hooks/useJobs';
import { jobLinkClicked, loadDiscardJob, loadFavoriteJob, moreJobsRequest } from '../../../redux/actions/jobs';
import { toggleSignUpModal } from '../../../redux/actions/userActions';
import { isAuthenticatedSelector, makeSelectFavoriteJobIds } from '../../../redux/selectors/user';
import { NetworkSchema } from '../../../schema/network';
import { hasActiveJobAlertSelector } from '../../../redux/selectors/jobAlertSelectors';
import useFilterParser from '../../../hooks/useFilterParser';
import { jobAlertsCardPositions } from '../../../constants/jobAlertsCardPositions';
import CreateJobAlertPanel from '../subscribeJobAlertsCard/createJobAlertPanel';
import { useJobAlerts } from '../../../hooks/useJobAlert';

const SubscribeJobAlertsCard = dynamic(() => import('../subscribeJobAlertsCard'), { ssr: false });

export const JobsResultsList = ({
  network,
  canDiscard,
  canFavorite,
  showDiscardedOverlay,
  defaultQuery,
  simplifiedResults,
  showJobAlertsButton,
}) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const dispatch = useDispatch();
  const { jobs, total, isLoading, initialized, isLazyLoading, nextPage } = useJobs({ defaultQuery, network });
  const favoriteJobIds = useSelector(makeSelectFavoriteJobIds);
  const isFavorite = (job) => favoriteJobIds.indexOf(job.id) > -1;
  const hasActiveJobAlert = useSelector(hasActiveJobAlertSelector);
  const { filtersHaveChanged, hasFilters, filters } = useFilterParser();
  const { subscribing, onSubmit, showJobFunctionSelector, jobFunctions } = useJobAlerts(network, 'no_results');

  const noResultsContent = useMemo(() => {
    if (showJobAlertsButton) {
      return (
        <>
          {!filtersHaveChanged && (
            <Text color="text.main" mb={[2]}>
              There are currently no jobs matching your search criteria.
            </Text>
          )}
          {filtersHaveChanged && (
            <Box sx={{ marginTop: '24px' }}>
              <Card tx="card" variant="subscribeToJobAlerts" data-testid="subscribe-to-jobalerts-card">
                <CreateJobAlertPanel
                  title="Be the first to know when new jobs are available"
                  description="Get new jobs matching this search in your inbox."
                  onSubmit={onSubmit}
                  showJobFunctions={showJobFunctionSelector}
                  subscribing={subscribing}
                  jobFunctions={jobFunctions.map(({ id, name }) => ({ value: id, label: name }))}
                />
              </Card>
            </Box>
          )}
          {!filtersHaveChanged && (
            <Text color="text.main" mb={[4]}>
              You will get an email with new jobs when they are available.
            </Text>
          )}
        </>
      );
    }

    return 'There are no job openings with this criteria, try changing your filters.';
  }, [showJobAlertsButton, filtersHaveChanged, onSubmit, showJobFunctionSelector, subscribing, jobFunctions]);

  const showSubscribeJobAlertsCard = useCallback(
    (index) => {
      if (!showJobAlertsButton) return false;
      if (total <= jobAlertsCardPositions[0] && index === total - 1) return true;

      return jobAlertsCardPositions.indexOf(index) >= 0;
    },
    [showJobAlertsButton, total],
  );

  const cardIndexFor = useCallback(
    (index) => {
      if (total <= jobAlertsCardPositions[0] && index === total - 1) return 1;

      return jobAlertsCardPositions.indexOf(index) + 1;
    },
    [total],
  );

  const onJobLinkClick = (payload = {}) => {
    dispatch(jobLinkClicked({ ...payload, network, filtersApplied: hasFilters, query: filters?.q || '' }));
  };

  return (
    <ResultsList
      network={network}
      isInitialized={initialized}
      isLoading={isLoading}
      isLazyLoading={isLazyLoading}
      canLoadMore={jobs.length < total && !isLazyLoading}
      onLoadMore={() => {
        dispatch(moreJobsRequest({ network }));
        nextPage();
      }}
      items={jobs}
      pb={4}
      listType={['job', 'jobs']}
      total={total}
      showJobAlertsButton={showJobAlertsButton}
      hasActiveJobAlert={hasActiveJobAlert}
      noResults={<SearchResultsEmpty title="No jobs matching this criteria" content={noResultsContent} />}
    >
      {jobs.map((item, index) => (
        <Fragment key={item.id}>
          <JobListItem
            id={item.id}
            onJobLinkClick={(payload = {}) => onJobLinkClick({ ...payload, position: index })}
            showDiscardedOverlay={showDiscardedOverlay}
            canFavorite={canFavorite}
            canDiscard={canDiscard}
            isFavorite={isFavorite(item)}
            isDiscarded={item.isDiscarded}
            isFeatured={item.featured}
            onDiscard={() => {
              if (isAuthenticated) {
                dispatch(loadDiscardJob({ network, job: item, discarded: !item.isDiscarded }));
              } else {
                dispatch(toggleSignUpModal({ network, id: item.id, type: 'job', button: 'discard' }));
              }
            }}
            onFavorite={() => {
              if (isAuthenticated) {
                dispatch(loadFavoriteJob({ network, job: item, favorite: !isFavorite(item) }));
              } else {
                dispatch(toggleSignUpModal({ network, button: 'like', id: item.id, type: 'job' }));
              }
            }}
            isLast={index === jobs.length - 1}
            key={item.id}
            createdAt={item.createdAt}
            slug={item.slug}
            source={item.source}
            locations={item.locations}
            organization={item.organization}
            title={item.title}
            description={item.description}
            seniority={item.seniority}
            url={item.url}
            simplifiedVersion={simplifiedResults}
            network={network}
            hasDescription={item.hasDescription}
            compensation={{
              amountMinCents: item.compensationAmountMinCents,
              amountMaxCents: item.compensationAmountMaxCents,
              currency: item.compensationCurrency,
              period: item.compensationPeriod,
              offersEquity: item.compensationOffersEquity,
              convertedAmountMinCents: item.compensationConvertedAmountMinCents,
              convertedAmountMaxCents: item.compensationConvertedAmountMaxCents,
              convertedCurrency: item.compensationConvertedCurrency,
            }}
          />
          {showSubscribeJobAlertsCard(index) && (
            <SubscribeJobAlertsCard network={network} cardIndex={cardIndexFor(index)} />
          )}
        </Fragment>
      ))}
    </ResultsList>
  );
};

JobsResultsList.propTypes = {
  network: NetworkSchema.isRequired,
  defaultQuery: PropTypes.object,
  showDiscardedOverlay: PropTypes.bool,
  canFavorite: PropTypes.bool,
  canDiscard: PropTypes.bool,
  simplifiedResults: PropTypes.bool,
  showJobAlertsButton: PropTypes.bool,
};

JobsResultsList.defaultProps = {
  defaultQuery: {},
  showDiscardedOverlay: true,
  canFavorite: true,
  canDiscard: true,
  simplifiedResults: false,
  showJobAlertsButton: false,
};

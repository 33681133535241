import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { ListFilter } from '../../molecules/listFilter';
import useFilters from '../../../hooks/useFilters';
import useRefreshDataOnQueryChange from '../../../hooks/useRefreshDataOnQueryChange';
import { allJobsRequest } from '../../../redux/actions/jobs';
import { NetworkSchema } from '../../../schema/network';
import { filterTagsSelector } from '../../../helpers/filterTags';
import { jobsSelector } from '../../../redux/selectors/jobs';
import { RemoteLocationFilter } from '../../molecules/locationFilter/remoteLocationFilter';
import { showJobAlertsSelector } from '../../../redux/selectors/network';
import { CompanySchema } from '../../../schema/company';

const JobAlert = dynamic(() => import('../jobAlertOnFilter'), { ssr: false });

const JobListFilters = ({
  network,
  company,
  defaultQuery,
  searchPlaceholder,
  filterOptions,
  isLoading,
  ...rebassProps
}) => {
  const { jobBoardFilters } = network;
  const { locationFilter } = jobBoardFilters;
  const hideLocationFilter = !locationFilter;
  useRefreshDataOnQueryChange({ action: allJobsRequest, network });
  const { query, filterBySearchTerm, updateFilters, resetFilters } = useFilters(defaultQuery, network);
  const jobsData = useSelector(jobsSelector);
  const showJobAlerts = showJobAlertsSelector(network);

  const allCompanies = useMemo(
    () => filterOptions.find((f) => f.queryParam === 'organization.id')?.options || [],
    [filterOptions],
  );

  const tags = useMemo(() => {
    const currentQuery = { ...query, ...defaultQuery };

    delete currentQuery.stage;
    delete currentQuery.headCount;
    delete currentQuery.industryTags;

    return filterTagsSelector({
      allCompanies,
      query: currentQuery,
    });
  }, [query, defaultQuery, allCompanies]);

  return (
    <ListFilter
      network={network}
      company={company}
      isLoading={isLoading}
      searchPlaceholder={searchPlaceholder}
      tags={tags}
      onRemoveTag={({ tag, queryParam }) => {
        const filter = { queryParam, value: tag.id, name: tag.name };
        updateFilters({ filter, mode: 'remove' });
      }}
      searchValue={query.q}
      my={4}
      rightItem={<JobAlert network={network} />}
      updateFilters={updateFilters}
      hideLocationFilter={hideLocationFilter}
      filterOptions={filterOptions}
      itemCount={jobsData?.total || 0}
      filterBySearchTerm={filterBySearchTerm}
      resetFilters={resetFilters}
      searchRowFilters={
        !hideLocationFilter ? (
          <>
            <RemoteLocationFilter
              locationOptionTag={tags.find((tag) => tag.queryParam === 'searchable_location_option')?.values ?? []}
              updateFilters={updateFilters}
              hasAdvanceDesign={network.features.includes('advanced_design') !== -1}
            />
          </>
        ) : null
      }
      {...rebassProps}
      showJobAlert={showJobAlerts}
    />
  );
};

JobListFilters.propTypes = {
  isLoading: PropTypes.bool,
  network: NetworkSchema.isRequired,
  company: CompanySchema,
  searchPlaceholder: PropTypes.string.isRequired,
  filterOptions: PropTypes.array,
  defaultQuery: PropTypes.object,
};

JobListFilters.defaultProps = {
  company: null,
  isLoading: false,
  defaultQuery: {},
  filterOptions: null,
};

export default JobListFilters;
